import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRefService } from '@services/window-ref-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { DataStoreService } from '@services/data-store-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AppConfig } from 'client/app/app.config';
import { WrongPhotoDetectedComponent } from '@shared/bottom-sheet-layouts/wrong-photo-detected/wrong-photo-detected.component';
import { Subscription } from 'rxjs';
import { InstantCheckupService } from '@services/instant-checkup-service';
import { DeletePhotoSheetComponent } from '@shared/bottom-sheet-layouts/delete-photo-sheet/delete-photo-sheet.component';
import { problemsCopy, concernSeverityTranslations } from '../../problem-details';
import { FACE_PROBLEM_CLASS_MAPPING } from './face-problem-class-mappings';

interface ConcernSeverity {
  'Mild': string;
  'Moderate': string;
  'Severe': string;
}

interface ConcernSeverityThreshold {
  [key: string]: {
    mild: number;
    moderate: number;
  };
}

interface Point {
  x: number;
  y: number;
}

@Component({
  selector: 'app-checkup-view-experiment',
  templateUrl: './checkup-view-experiment.component.html',
  styles: [':host {@apply tw-flex tw-flex-col tw-h-full tw-bg-gray-100;}'],
})
export class CheckupViewExperimentComponent {
  @Input() isFromListView: boolean = false;
  @Input('experiments') experiments: any[];
  @Input() instantCheckup: any;
  @ViewChild('cameraInput', { static: true }) cameraInput: ElementRef;

  @ViewChild('swiperRef', { static: false }) swiper: SwiperComponent;
  image: HTMLImageElement;
  faceLandmarks: any;
  ui: any = {};
  user: any;
  results: any[] = [];
  croppingConfig: { top: number, left: number, ratio: number };
  selectedDetection: any;
  problemsCopy: { [key: string]: { [key: string]: { name: string, info: string } } } = problemsCopy;
  concernSeverityTranslations: { [key: string]: { [key: string]: string } } = concernSeverityTranslations;
  loading: boolean = true;
  isDeleteCheckupImagePopup: boolean = false;
  tag: any;
  consultationSession: any;

  carouselConfig: SwiperOptions = {
    slidesPerView: 1.1,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    autoplay: false,
    height: 120,
  };

  concernSeverity: ConcernSeverity = {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
  };
  concernSeverityThreshold: ConcernSeverityThreshold = {
    'pimple-region': {
      mild: 2,
      moderate: 5,
    },
    'darkspot-region': {
      mild: 4,
      moderate: 8,
    },
    'come-region': {
      mild: 4,
      moderate: 8,
    },
    'ascar-region': {
      mild: 2,
      moderate: 5,
    },
    PerioralPigmentation: { // Always severe for now
      mild: 0,
      moderate: 0,
    },
    OpenPores: { // Always Moderate for now
      mild: 0,
      moderate: 100,
    },
  };
  faceType: string;
  shouldShowIncorrectPhotoDetectedSheet: boolean;
  doesPhotoTypeMatches: boolean;
  instantCheckupIdFromRoute: any;
  subscriptions: Array<Subscription> = [];
  isFaceConcernFocusExperimentEnabled: boolean = false;
  faceChatMainConcern: string;
  faceChatSecondaryConcerns: string[];
  isShowHairlineDetectionExperiment: boolean;
  isHairlineDetected: boolean;
  isShowTempleBaldnessDetectionExperiment: boolean;
  isTempleBaldnessDetected: boolean;
  handleBlurPhotosExperiment: boolean;
  noBlurDetected: boolean;
  blurWithConcernsDetected: boolean;
  blurWithoutConcernsDetected: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private connectionService: ConnectionService,
    private appWebBridge: AppWebBridgeService,
    private eventLogger: EventLoggerService,
    private windowRef: WindowRefService,
    private dataStore: DataStoreService,
    private broadcast: BroadcastService,
    public route: ActivatedRoute,
    private appConfig: AppConfig,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private changeDetection: ChangeDetectorRef,
    private readonly instantCheckupService: InstantCheckupService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.isFromListView = this.route.snapshot.queryParams.fromList;

    this.experiments = await this.connectionService.findUserActiveExperiments();
    this.experiments?.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'delete_checkup_image_popup') {
        this.isDeleteCheckupImagePopup = true;
      }
      if (experiment.key === 'show_hairline_detection') {
        this.isShowHairlineDetectionExperiment = true;
      }
      if (experiment.key === 'show_temple_baldness_detection') {
        this.isShowTempleBaldnessDetectionExperiment = true;
      }
      if (experiment.key === 'face_concern_focus') {
        this.isFaceConcernFocusExperimentEnabled = true;
      }
      if (experiment.key === 'handle_blur_photos') {
        this.handleBlurPhotosExperiment = true;
      }
    });
    this.user = this.connectionService.getActingUser();
    this.subscriptions.push(this.route.params.subscribe(async (param: any): Promise<void> => {
      this.instantCheckupIdFromRoute = param.id;
      await this.getInstantCheckup(this.instantCheckupIdFromRoute);
      this.loading = false;
    }));
  }

  reorderResultsByConcerns(): any[] {
    const moveMatchingResultsToFront = (concernKeys: string[], results: any[]): any[] => {
      const classesToMove = new Set<string>(
        concernKeys.flatMap((key: string): string[] => FACE_PROBLEM_CLASS_MAPPING[key] || []),
      );

      const [movedResults, remainingResults]: [any[], any[]] = results.reduce(
        ([moved, remaining]: [any[], any[]], result: any): [any[], any[]] => {
          if (classesToMove.has(result.class)
            && !moved.some((r: any): boolean => r.class === result.class && r.ProblemName === result.ProblemName)) {
            moved.push(result);
          } else {
            remaining.push(result);
          }
          return [moved, remaining];
        },
        [[], []],
      );

      return [...movedResults, ...remainingResults];
    };

    let reorderedResults = [...this.results];

    // eslint-disable-next-line angular/typecheck-array
    if (Array.isArray(this.faceChatSecondaryConcerns) && this.faceChatSecondaryConcerns.length > 0) {
      reorderedResults = moveMatchingResultsToFront(this.faceChatSecondaryConcerns, reorderedResults);
    }

    if (this.faceChatMainConcern && FACE_PROBLEM_CLASS_MAPPING[this.faceChatMainConcern]) {
      reorderedResults = moveMatchingResultsToFront([this.faceChatMainConcern], reorderedResults);
    }

    return reorderedResults;
  }

  /**
   * Fetch instant checkup and populate landmarks, image, detection etc.
   */
  async getInstantCheckup(idFromRoute: string): Promise<void> {
    [this.instantCheckup] = await this.connectionService.fetchInstantCheckup({ id: [idFromRoute] });
    this.checkForBlurryImage(this.instantCheckup);
    this.image = await this.loadImage(this.instantCheckup?.imagePath);
    this.faceLandmarks = this.getLandmarks(this.instantCheckup);
    this.changeDetection.detectChanges();

    if (['LEFT_SIDE_FACE', 'FRONT_FACE', 'RIGHT_SIDE_FACE'].includes(this.instantCheckup.type)) {
      const results = [...(this.instantCheckup?.aiResponse?.result || [])];
      this.results = [...results
        .filter((result: any): boolean => result.Condition === 'Detected')
        .filter((result: any): boolean => result.class !== 'Melasma'
          || (result.class === 'Melasma'
            && (<string>result.Severity)?.toLowerCase() !== 'mild'
            && !['LEFT_SIDE_FACE', 'RIGHT_SIDE_FACE'].includes(this.instantCheckup.type)),
        ),
      ];

      if (this.isFaceConcernFocusExperimentEnabled) {
        try {
          /*
          TODO: Instead of using FACE to get the consultation session, we will use the user selected regimen class
          once we have the feature to show analysis result for HAIR as well so that it works for both FACE and HAIR.
          */
          this.consultationSession = await this.connectionService.findConsultationSession(this.appConfig.Shared.Regimen.Class.FACE);
          this.faceChatMainConcern = await this.consultationSession?.get('PrivateMainConcern');
          this.faceChatSecondaryConcerns = await this.consultationSession?.get('concernList');
          this.results = this.reorderResultsByConcerns();
        } catch (err) {
          // handle error
        }
      }

      // check severity
      this.results = this.results.map((result: any): any => {
        const severity = this.instantCheckupService.getSeverityFromConcern(result);
        return { ...result, severity };
      });
    } else {
      this.results = [];
      if (this.isShowTempleBaldnessDetectionExperiment
        && (this.instantCheckup.aiResponse?.ellipse_points?.left?.length
          || this.instantCheckup.aiResponse?.ellipse_points?.right?.length)) {
        this.isTempleBaldnessDetected = true;
        this.results = [{
          PolygonPointsSequences: [
            [...(this.instantCheckup.aiResponse?.ellipse_points?.left || [])],
            [...(this.instantCheckup.aiResponse?.ellipse_points?.right || [])],
          ],
        }];
      }
      if (this.isShowHairlineDetectionExperiment && this.instantCheckup.aiResponse?.points_data?.hairline_points?.length) {
        this.isHairlineDetected = true;
        this.results = [{
          PolygonPointsSequences: [
            ...(this.results?.[0]?.PolygonPointsSequences || []),
            { color: '#ffffff', points: (this.instantCheckup.aiResponse?.points_data?.hairline_points || []) },
          ],
        }];
      }
    }

    if (this.instantCheckup.type === this.appConfig.Shared.InstantCheckup.Type.INVALID) {
      this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
        branch: { name: 'instantCheckupFaceNotDetected' },
        firebase: { name: 'instantCheckupFaceNotDetected' },
      });
    }

    this.tag = this.instantCheckup.originalType || this.instantCheckup.type;
    if (this.tag === this.appConfig.Shared.InstantCheckup.Type.INVALID) {
      this.tag = this.appConfig.Shared.InstantCheckup.Type.FULL_FACE;
    }

    if (!this.isFromListView) {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({
        pageName: 'instant-checkup-detection',
        class: (<string> this.instantCheckup.type).includes('FACE') ? 'FACE' : 'HAIR',
        type: this.instantCheckup.type,
        detectionCount: this.results?.length || 0,
      }));
    }
    this.loading = false;
  }

  checkForBlurryImage(checkup: any): void {
    const aiResponse = checkup?.aiResponse;
    const result = aiResponse?.result || [];
    const concernsDetected = result.some((concern: any): boolean => concern?.Condition === 'Detected');
    const blurScore = aiResponse?.blurScore;
    const assessmentThreshold = aiResponse?.blurStandard?.assessment_threshold;

    const rangeMin = assessmentThreshold?.range?.min || 0;
    const rangeMax = assessmentThreshold?.thresholds?.[0]?.blur || Infinity;

    const blurDetected: boolean = blurScore > rangeMin && blurScore < rangeMax;
    if (blurDetected) {
      if (concernsDetected) {
        // Blur detected with concerns - Show disclaimer
        this.blurWithConcernsDetected = true;
        this.triggerCustomEvent('blur_with_concerns');
      } else {
        // Blur detected, no concerns - Show blur message in carousel
        this.blurWithoutConcernsDetected = true;
        this.triggerCustomEvent('blur_without_concerns');
      }
    } else {
      this.noBlurDetected = true;
      this.triggerCustomEvent('no_blur');
    }
  }

  triggerCustomEvent(eventName: string): void {
    this.eventLogger.cleverTapEvent(`${eventName}`, JSON.stringify({ pageName: 'InstantCheckup' }));
  }

  getLandmarks(instantCheckup: any): { top: Point, left: Point, right: Point, bottom: Point, center: Point, faceBox: any } {
    // eslint-disable-next-line operator-linebreak
    if (['LEFT_SIDE_FACE', 'FRONT_FACE', 'RIGHT_SIDE_FACE'].includes(instantCheckup.type)) {
      const landmarks = this.instantCheckup?.aiResponse?.googleResponse?.faceAnnotations?.[0]?.landmarks;
      const distanceBetweenChinAndNose = (landmarks?.CHIN_GNATHION.y || 0) - (landmarks?.NOSE_TIP.y || 0);
      return {
        top: {
          x: landmarks?.FOREHEAD_GLABELLA?.x,
          y: (landmarks?.FOREHEAD_GLABELLA?.y || 0)
            - distanceBetweenChinAndNose,
        },
        left: landmarks?.LEFT_EAR_TRAGION,
        bottom: landmarks?.CHIN_GNATHION,
        right: landmarks?.RIGHT_EAR_TRAGION,
        center: landmarks?.NOSE_TIP,
        faceBox: this.instantCheckup?.aiResponse?.faceBox,
      };
    }

    const landmarks = this.instantCheckup?.aiResponse?.fb_landmarks;
    const distanceBetweenChinAndNose = (landmarks?.CHIN_GNATHION?.[1] || 0)
      - (landmarks?.NOSE_TIP?.[1] || 0);
    return {
      top: {
        x: landmarks?.FOREHEAD_GLABELLA?.[0],
        y: (landmarks?.FOREHEAD_GLABELLA?.[1] || 0)
          - distanceBetweenChinAndNose,
      },
      left: {
        x: landmarks?.LEFT_EAR_TRAGION?.[0],
        y: landmarks?.LEFT_EAR_TRAGION?.[1],
      },
      bottom: {
        x: landmarks?.CHIN_GNATHION?.[0],
        y: landmarks?.CHIN_GNATHION?.[1],
      },
      right: {
        x: landmarks?.RIGHT_EAR_TRAGION?.[0],
        y: landmarks?.RIGHT_EAR_TRAGION?.[1],
      },
      center: {
        x: landmarks?.NOSE_TIP?.[0],
        y: landmarks?.NOSE_TIP?.[1],
      },
      faceBox: null,
    };
  }

  /**
   * Select active skin detection
   * @param detection current active detection
   */
  redrawScaledCanvas(zoomBox?: HTMLDivElement): void {
    const overaly = this.windowRef.nativeWindow.document.getElementById('overlayCanvas');
    if (overaly) overaly.parentElement.removeChild(overaly);
    if (zoomBox) zoomBox.removeAttribute('style');
    if (zoomBox) this.findScaling(zoomBox);
  }

  slide(swiper: SwiperComponent): void {
    const swiperRef = swiper ? swiper.swiperRef : this.swiper?.swiperRef;
    if (!swiperRef) return;

    if (!this.selectedDetection) {
      swiperRef.slideTo(0);
      return;
    }

    const index = this.results.indexOf(this.selectedDetection) + 1;
    swiperRef.slideTo(index);
  }

  selectDetection(event: any): void {
    const [swiperEvent]: any = event;
    if (swiperEvent.activeIndex === 0) {
      this.selectedDetection = null;
    } else {
      this.selectedDetection = this.results[swiperEvent.activeIndex - 1];
    }
  }

  findScaling(zoomBox: HTMLDivElement): void {
    const zoomBoxWidth = zoomBox.offsetWidth;
    const zoomBoxHeight = zoomBox.offsetHeight;
    const zoomBoxAspectRatio = zoomBoxWidth / zoomBoxHeight;
    const zoomBoxCenter = {
      x: zoomBoxWidth / 2,
      y: zoomBoxHeight / 2,
    };
    let points = { minX: Number.MAX_VALUE, minY: Number.MAX_VALUE, maxX: Number.MIN_VALUE, maxY: Number.MIN_VALUE };
    if (this.selectedDetection.BoundingBoxes) points = this.findBoundingBox(this.selectedDetection.BoundingBoxes);
    if (this.selectedDetection.PolygonPointsSequences) points = this.findBoundingPolygon(this.selectedDetection.PolygonPointsSequences);

    points = {
      minX: (points.minX - this.croppingConfig.left) * this.croppingConfig.ratio,
      minY: (points.minY - this.croppingConfig.top) * this.croppingConfig.ratio,
      maxX: (points.maxX - this.croppingConfig.left) * this.croppingConfig.ratio,
      maxY: (points.maxY - this.croppingConfig.top) * this.croppingConfig.ratio,
    };

    let boundingBoxWidth = points.maxX - points.minX;
    let boundingBoxHeight = points.maxY - points.minY;
    const boundingBoxCenter = {
      x: points.minX + boundingBoxWidth / 2,
      y: points.minY + boundingBoxHeight / 2,
    };

    // draw overlay here
    this.drawOverlay(zoomBox, { x: points.minX, y: points.minY }, boundingBoxWidth, boundingBoxHeight);
    // -----------------

    boundingBoxHeight += 100;
    boundingBoxWidth += 100;

    const boundigBoxAspectRatio = boundingBoxWidth / boundingBoxHeight;
    if (boundigBoxAspectRatio > zoomBoxAspectRatio) {
      boundingBoxHeight = (zoomBoxHeight / zoomBoxWidth) * boundingBoxWidth;
    }
    const scale = Math.min((zoomBoxHeight / boundingBoxHeight), 2.5);
    if (scale <= 1) return;

    const x = (zoomBoxCenter.x - boundingBoxCenter.x);
    const y = (zoomBoxCenter.y - boundingBoxCenter.y);

    const maxTranslateX = (zoomBox.clientWidth * scale - zoomBox.clientWidth) / 2;
    const minTranslateX = -(zoomBox.clientWidth * scale - zoomBox.clientWidth) / 2;
    const maxTranslateY = (zoomBox.clientHeight * scale - zoomBox.clientHeight) / 2;
    const minTranslateY = -(zoomBox.clientHeight * scale - zoomBox.clientHeight) / 2;

    const translate = {
      x: Math.sign(x * scale) === 1 ? Math.min(x * scale, maxTranslateX) : Math.max(x * scale, minTranslateX),
      y: Math.sign(y * scale) === 1 ? Math.min(y * scale, maxTranslateY) : Math.max(y * scale, minTranslateY),
    };

    // zoomBox.style.setProperty('transform-origin', `${origin.x}px ${origin.y}px`);
    zoomBox.style.setProperty('translate', `${translate.x}px ${translate.y}px`);
    zoomBox.style.setProperty('transform', `scale(${scale})`);
  }

  findBoundingBox(param: string[]): { minX: number, minY: number, maxX: number, maxY: number } {
    let points = { minX: Number.MAX_VALUE, minY: Number.MAX_VALUE, maxX: Number.MIN_VALUE, maxY: Number.MIN_VALUE };
    param.forEach((pointString: string): void => {
      const pointArray = pointString.split(',').map((x: string): number => +x);
      points = {
        minX: Math.min(pointArray[0], pointArray[2], points.minX),
        minY: Math.min(pointArray[1], pointArray[3], points.minY),
        maxX: Math.max(pointArray[0], pointArray[2], points.maxX),
        maxY: Math.max(pointArray[1], pointArray[3], points.maxY),
      };
    });
    return points;
  }
  findBoundingPolygon(polygons: string[][]): { minX: number, minY: number, maxX: number, maxY: number } {
    let points = { minX: Number.MAX_VALUE, minY: Number.MAX_VALUE, maxX: Number.MIN_VALUE, maxY: Number.MIN_VALUE };
    polygons.forEach((polygon: string[]): void => {
      polygon.forEach((pointString: string): void => {
        const pointArray = pointString.split(',').map((x: string): number => +x);
        points = {
          minX: Math.min(pointArray[0], points.minX),
          minY: Math.min(pointArray[1], points.minY),
          maxX: Math.max(pointArray[0], points.maxX),
          maxY: Math.max(pointArray[1], points.maxY),
        };
      });
    });
    return points;
  }

  drawOverlay(zoomBox: HTMLDivElement, point: { x: number, y: number }, width: number, height: number): void {
    const canvas = this.windowRef.nativeWindow.document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    canvas.height = zoomBox.clientHeight;
    canvas.width = zoomBox.clientWidth;
    canvas.id = 'overlayCanvas';
    canvas.style.setProperty('position', 'absolute');
    canvas.style.setProperty('top', '0');
    canvas.style.setProperty('left', '0');
    canvasContext.fillStyle = 'rgba(0, 0, 0, 0.5)';
    canvasContext.fillRect(0, 0, canvas.width, canvas.height);
    canvasContext.stroke();
    canvasContext.beginPath(); // Start a new path
    canvasContext.clearRect(point.x - 10, point.y - 10, width + 20, height + 20);
    canvasContext.rect(point.x - 10, point.y - 10, width + 20, height + 20);
    canvasContext.lineWidth = 3;
    canvasContext.strokeStyle = 'rgba(255, 255, 255, 0.65)';
    canvasContext.stroke();

    zoomBox.appendChild(canvas);
  }

  deleteInstantCheckup(confirmDelete?: boolean): void {
    if (this.ui.deleteLoading || !this.instantCheckup) return;
    this.ui.deleteLoading = true;
    this.connectionService.deleteInstantCheckup(this.instantCheckup.objectId, confirmDelete)
      .then((): void => {
        this.notify('Deleted Successfully');
        this.back();
        this.ui.deleteLoading = false;
      })
      .catch((err: any): void => {
        if (err.code === 409 && this.isDeleteCheckupImagePopup) {
          this.bottomSheet.open(DeletePhotoSheetComponent, {
            data: {
              content: err.message || err,
              clickNewPhotoCallback: (): void => {
                this.goCheckAgain();
              },
              deleteCallback: (): void => {
                this.deleteInstantCheckup(true);
              },
            },
          });
          this.ui.deleteLoading = false;
        } else {
          const message = err.message || err;
          this.ui.popUpModal = {
            title: this.appConfig.Shared.String.DELETE_PHOTO,
            open: true,
            okText: this.appConfig.Shared.String.DELETE,
            cancelText: this.appConfig.Shared.String.KEEP_PHOTO,
            message: { text: message },
            type: err.code === 409 ? this.appConfig.Dialog.CONFIRMATION : this.appConfig.Dialog.ALERT,
          };
          this.ui.deleteLoading = false;
        }
      });
  }

  back(step?: number): void {
    this.broadcast.broadcast('NAVIGATION_BACK', { step });
  }

  /**
   * Load an image in promise
   * @param src image src
   * @returns HTMLImageElement
   */
  async loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve: any, reject: any): void => {
      const image = new Image();
      image.src = src;
      image.onload = (): void => resolve(image);
      image.onerror = (): void => {
        reject(new Error('Cant process image'));
      };
    });
  }

  popUpClosed(result: any): void {
    this.ui.popUpModal = { open: false };
    if (result.clickOnYes) {
      this.deleteInstantCheckup(true);
    }
  }

  notify(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  checkAndOpenIncorrectFaceDetectedBottomSheet(): void {
    const expectedPhotoType = this.localStorageService?.getValue('expectedFacePhoto');
    if (!expectedPhotoType) {
      return;
    }
    const instantCheckupPhotoType = this.instantCheckup.type;
    this.doesPhotoTypeMatches = instantCheckupPhotoType === expectedPhotoType || instantCheckupPhotoType.includes(expectedPhotoType);
    this.shouldShowIncorrectPhotoDetectedSheet = (instantCheckupPhotoType.includes(this.appConfig.Shared.InstantCheckup.Type.FACE)
      && !this.doesPhotoTypeMatches);
    if (this.shouldShowIncorrectPhotoDetectedSheet) {
      this.bottomSheet.open(WrongPhotoDetectedComponent, {
        data: {
          photoTypes: { expectedPhoto: expectedPhotoType, actualPhoto: instantCheckupPhotoType },
          retakePhotoFromBottomSheet: (): void => {
            this.handleImageCapture();
          },
        },
      });
    }
  }

  handleImageCapture(): void {
    const tag = this.tag.includes('FACE')
      ? this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE
      : this.tag;
    const queryParams: any = { tag };
    if (this.appWebBridge.isAppWebBridgeLoaded()) {
      this.appWebBridge.notifyWebLoginToken();
      const extraData = { ...(this.route.snapshot.queryParams || {}) };
      delete extraData.tag;
      this.appWebBridge.notifyCaptureImage(tag, {}, extraData);
    } else if (this.cameraInput) {
      this.cameraInput.nativeElement.click();
    } else {
      this.windowRef.nativeWindow.document.getElementById('cameraInput').click();
    }
  }

  /**
   * This function decides where to take the user to when they click 'continue' button in footer.
   * 1. if no main-concern, then it navigates user to either mainconcern selection page or photo taking page based on experiment.
   * 2. If there is any redirect url set it local-storage, it takes them to there.
   * 3. Else to chat page.
   */
  async continueToNext(): Promise<any> {
    this.checkAndOpenIncorrectFaceDetectedBottomSheet();
    if (this.shouldShowIncorrectPhotoDetectedSheet) return null;
    const isFromTakePhotoBanner = this.localStorageService.getBooleanValue('takePhotoBannerClicked');
    if (isFromTakePhotoBanner) {
      this.eventLogger.trackEvent('photo_taken_upload_photo_banner', { username: this.user.get('username') });
      this.localStorageService.delete('takePhotoBannerClicked');
    }
    const redirectTo = this.localStorageService.getValue('CureSkin/redirectUrl');
    if (redirectTo) return this.connectionService.redirectToLastKnowLocation();
    this.eventLogger.trackEvent('continue_to_chat_from_instant_checkup', { username: this.user.get('username') });
    if (this.user.get('followupTreeTriggered')) {
      const followUp = await this.connectionService.findRecentFollowUp({
        State: [
          this.appConfig.Shared.Followup.State.PENDING,
          this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE,
        ],
        ready: false,
      });
      return this.router.navigate([`/chatV2/${followUp?.id}`], {
        queryParams: { type: 'followUp' },
      });
    }
    const routeString = await this.checkForRegimenAndNavigate();
    if (routeString) {
      return this.router.navigate(['/user'], { queryParams: { tab: 'regimen', class: routeString } });
    }
    return this.router.navigate(['/user'], { queryParams: { tab: 'home' } });
  }

  async checkForRegimenAndNavigate(): Promise<string> {
    const regimenClasses = [this.appConfig.Shared.Regimen.Class.FACE, this.appConfig.Shared.Regimen.Class.HAIR];
    const regimens = await this.connectionService.fetchRegimens(null, true);

    const foundClass = regimenClasses.find((regimenClass: any): boolean => {
      const includesTag = this.tag.includes(regimenClass);
      return includesTag && !this.hasPaidRegimen(regimens, regimenClass);
    });

    return foundClass || '';
  }

  hasPaidRegimen(regimens: any[], regimenClass: string): boolean {
    return regimens.some((regimen: any): boolean => regimen?.active && regimen?.class === regimenClass);
  }

  /**
  * When Check again button is clicked.
  * 1. Calls native to open camera when its inside native app.
  * 2. Open Native camera through web when experiment is enabled.
  * 3. Routes to capture page, which is a web-cam(video) based capturing experience. We capture the image of video.
  */
  async goCheckAgain(): Promise<any> {
    this.loading = true;
    this.eventLogger.trackEvent('check_again', { username: this.user.get('username'), type: this.tag });
    this.eventLogger.trackPeopleIncrement({ people_take_photo_clicked: 1 });
    this.handleImageCapture();
  }

  /**
   * Callback of native camera picture upload. i.e Native camera opened through web, not through app bridge.
   */
  async uploadImageFromNativeCamera(event: any): Promise<any> {
    const nativeCameraImage = event.target.files[0];
    await this.dataStore.set('IMAGE_FILE', { file: nativeCameraImage });
    this.router.navigate(['/user/instantCheckup/capture'],
      { queryParams: { tag: this.tag, nativeCameraFile: true }, replaceUrl: true });
  }

  ngOnDestroy(): void {
    this.subscriptions.map((each: Subscription): void => each.unsubscribe());
    this.subscriptions = [];
    this.deleteLocalStorageItem('expectedFacePhoto');
  }

  deleteLocalStorageItem(field: string): void {
    this.localStorageService.delete(field);
  }
}
