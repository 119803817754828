<div class="tw-w-full tw-h-[200px] tw-fixed tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-my-8 tw-mx-0"
     *ngIf="loading">
        <div class="tw-w-1/2 tw-h-full tw-relative tw-my-0 tw-mx-auto">
                <div
                     class="scanner before:tw-content-[''] before:tw-absolute before:tw-w-5 before:tw-h-4 before:tw-border-0 before:tw-border-solid before:tw-border-black before:tw-left-0 before:tw-top-0 before:tw-border-l-[1px] before:tw-border-t-[1px]   after:tw-content-[''] after:tw-absolute after:tw-w-5 after:tw-h-4 after:tw-border-0 after:tw-border-solid after:tw-border-black after:tw-right-0 after:tw-top-0 after:tw-border-r-[1px] after:tw-border-t-[1px]">
                        <em
                            class="before:tw-content-[''] before:tw-absolute before:tw-w-5 before:tw-h-4 before:tw-border-0 before:tw-border-solid before:tw-border-black before:tw-left-0 before:tw-bottom-0 before:tw-border-l-[1px] before:tw-border-b-[1px]  after:tw-content-[''] after:tw-absolute after:tw-w-5 after:tw-h-4 after:tw-border-0 after:tw-border-solid after:tw-border-black after:tw-right-0 after:tw-bottom-0 after:tw-border-r-[1px] after:tw-border-b-[1px]"></em>
                        <div
                             class="tw-absolute tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-w-[90%] tw-h-[5px] tw-z-[5] tw-bg-blue-200 tw-animate-scan_IC1">
                        </div>
                        <span class="cs-icon-male tw-absolute tw-text-[72px] tw-text-[rgba(0,0,0,0.4)] tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
                              *ngIf='user && user.get("Gender") === "Male"'></span>
                        <span class="cs-icon-female tw-absolute tw-text-[72px] tw-text-[rgba(0,0,0,0.4)] tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
                              *ngIf='user && user.get("Gender") !== "Male"'></span>
                </div>
        </div>
</div>
<ng-container *ngIf="!loading">
        <header class="tw-flex-none tw-w-full">
                <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
                        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
                             (click)="back()">
                                <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50"
                                         [iconName]="'arrow_back'"></cs-icon>
                        </div>
                        <span *ngIf="!['HAIR_FRONT'].includes(instantCheckup.type)"
                              class="tw-font-body tw-font-bold tw-text-300 tw-text-white"
                              i18n="@@skinAnalysisResult">Skin analysis result</span>
                        <span *ngIf="['HAIR_FRONT'].includes(instantCheckup.type)"
                              class="tw-font-body tw-font-bold tw-text-300 tw-text-white"
                              i18n="@@hairAnalysisResult">Hair analysis result</span>
                        <div
                             class="tw-absolute tw-right-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10">
                                <cs-icon class="tw-h-10 tw-w-10"
                                         [iconName]="'delete'"
                                         (click)="deleteInstantCheckup()"></cs-icon>
                        </div>
                </div>
        </header>
        <!-- Detections selection pills -->
        <section *ngIf="results?.length && !['HAIR_FRONT'].includes(instantCheckup.type)"
                 class="tw-flex-none tw-flex tw-items-center tw-gap-1 tw-py-2 tw-pl-4 tw-z-10 tw-overflow-x-scroll tw-backdrop-blur tw-bg-gray-100">
                <div class="tw-flex tw-items-center tw-justify-center tw-font-body tw-text-300 tw-text-gray-900 tw-bg-gray-50 tw-rounded-full tw-h-10 tw-px-4 tw-w-max tw-whitespace-nowrap tw-transition-all tw-shadow-z1"
                     [ngClass]="{
                                'tw-bg-black tw-text-gray-50': !selectedDetection?.class,
                                'tw-bg-gray-50 tw-text-gray-900': !!selectedDetection?.class,
                              }"
                     (click)="selectedDetection = null; slide(swiperRef);  slide(swiperRefOverlay); redrawScaledCanvas(zoomBox)"
                     i18n="@@allConcerns">
                        All
                </div>
                <div *ngFor="let detection of results"
                     class="tw-flex tw-items-center tw-justify-center tw-font-body tw-text-300 tw-text-gray-900 tw-bg-gray-50 tw-rounded-full tw-h-10 tw-px-4 tw-w-max tw-whitespace-nowrap tw-transition-all tw-shadow-z1"
                     [ngClass]="{
                                'tw-bg-black tw-text-gray-50': detection?.class === selectedDetection?.class,
                                'tw-bg-gray-50 tw-text-gray-900': detection?.class !== selectedDetection?.class,
                              }"
                     [appScrollToView]="detection?.class === selectedDetection?.class"
                     (click)="selectedDetection = detection; slide(swiperRef);  slide(swiperRefOverlay); redrawScaledCanvas(zoomBox)">
                        {{ problemsCopy[user?.get('languagePreference') || 'en'][detection.class]?.name}}
                </div>
        </section>
        <!-- Canvas drawing area -->
        <main #main
              class="tw-flex-auto tw-flex tw-flex-col tw-max-w-full tw-overflow-hidden tw-bg-black">
                <div #zoomBox
                     class="tw-relative tw-h-full tw-w-full tw-transition-all transition tw-duration-300 tw-ease-in-out">
                        <canvas #imageCanvas
                                class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full"
                                [appDrawImage]="image"
                                [faceLandmarks]="faceLandmarks"
                                (croppingConfig)="croppingConfig = $event"></canvas>
                        <ng-container *ngFor="let detection of results; even as isEven;">
                                <canvas class="tw-absolute tw-left-0 tw-top-0 tw-transition-all"
                                        [ngClass]="{
                                                  'tw-animate-fade_in_right': isEven && !selectedDetection,
                                                  'tw-animate-fade_in_left': !isEven && !selectedDetection,
                                                  'tw-animate-fade_out_right': isEven && selectedDetection && detection?.class !== selectedDetection?.class,
                                                  'tw-animate-fade_out_left': !isEven && selectedDetection && detection?.class !== selectedDetection?.class,
                                                }"
                                        [height]="imageCanvas?.clientHeight"
                                        [width]="imageCanvas?.clientWidth"
                                        [appDrawDetections]="detection"
                                        [config]="croppingConfig"></canvas>
                        </ng-container>
                        <swiper #swiperRefOverlay
                                class="tw-absolute tw-left-0 tw-top-0 tw-transition-all tw-h-full tw-w-full"
                                (slideChange)="selectDetection($event); slide(swiperRef); redrawScaledCanvas(zoomBox);">
                                <div *swiperSlide
                                     class="tw-h-full tw-w-full"></div>
                                <ng-container *ngFor="let detection of results;">
                                        <div *swiperSlide
                                             class="tw-h-full tw-w-full"></div>
                                </ng-container>
                        </swiper>
                  <ng-container *ngIf="blurWithConcernsDetected && handleBlurPhotosExperiment">
                    <div class="tw-absolute tw-top-3 tw-left-3 tw-right-3 tw-bg-orange-50 tw-border tw-border-orange-400 tw-px-4 tw-py-1 tw-mb-4 tw-text-left tw-rounded-lg tw-flex tw-justify-start tw-items-start">
                      <img src="/assets/icons/warning.svg" class="tw-w-4 tw-h-4 tw-mr-1" />
                      <h1 i18n="@@unclearImageError" class="tw-text-orange-400 tw-text-200 tw-font-body tw-font-bold">Image not clear, it is advised to retake photo</h1>
                    </div>
                  </ng-container>
                </div>
        </main>
        <!-- Detection card carousel -->
        <footer *ngIf="results?.length && !blurWithoutConcernsDetected"
                class="tw-flex-none tw-pb-1 tw-h-18 tw-bg-gray-100 tw-overflow-visible">
                  <swiper *ngIf="['LEFT_SIDE_FACE', 'FRONT_FACE', 'RIGHT_SIDE_FACE'].includes(instantCheckup.type)"
                          #swiperRef
                          [config]="carouselConfig"
                          class="-tw-mt-15 tw-px-4 tw-overflow-visible"
                          (slideChange)="selectDetection($event); slide(swiperRefOverlay); redrawScaledCanvas(zoomBox);">
                    <div *swiperSlide
                         class="tw-px-6 tw-py-4 tw-mr-3 tw-box-border tw-h-[136px] tw-bg-white tw-rounded-xl tw-z-20 tw-overflow-visible"
                         [ngClass]="{ 'tw-shadow-z3': !selectedDetection?.class }"
                         (click)="selectDetection({activeIndex: 1}); slide(swiperRef)">
                      <div class="tw-font-body tw-font-bold tw-text-500 tw-mb-1"
                           i18n="@@summary">
                        Summary
                      </div>
                      <div class="tw-flex tw-items-center tw-justify-start tw-gap-1 tw-mb-1">
                        <h1 class="tw-font-body tw-text-yellow-400 tw-text-200 tw-font-bold"
                            i18n="@@concernsDetected">
                          {{results.length}} {{results.length === 1 ? 'Concern' : 'Concerns' }}
                          Detected</h1>
                      </div>
                      <div
                        class="tw-font-body tw-text-200 tw-text-gray-700 tw-h-[54px] tw-line-clamp-3 tw-overflow-hidden tw-text-ellipsis">
                        <ng-container *ngFor="let item of results; let isLast = last;">
                          {{ problemsCopy[user?.get('languagePreference') ||
                        'en'][item?.class]?.name }}<span *ngIf="!isLast">, </span>
                        </ng-container>
                      </div>
                    </div>
                    <ng-container *ngFor="let item of results; let i = index">
                      <div *swiperSlide
                           class="tw-px-6 tw-py-4 tw-mr-3 tw-box-border tw-h-[136px] tw-bg-white tw-rounded-xl tw-z-20 tw-overflow-hidden tw-text-ellipsis"
                           [ngClass]="{
                                        'tw-shadow-z3': selectedDetection?.class === item?.class,
                                        'tw-bg-white': selectedDetection?.class === item?.class,
                                        'tw-bg-gray-100': selectedDetection?.class !== item?.class
                                      }">
                        <div
                          class="tw-font-body tw-font-bold tw-text-500 tw-mb-1 tw-line-clamp-1 tw-overflow-hidden tw-text-ellipsis">
                          {{ problemsCopy[user?.get('languagePreference') ||
                        'en'][item?.class]?.name }}
                        </div>
                        <div
                          class="tw-flex tw-items-center tw-justify-start tw-gap-1 tw-mb-1 tw-leading-4">
                          <h1 class="tw-font-body tw-text-200 tw-font-bold"
                              [ngClass]="{
                                                              'tw-text-gray-400': item?.severity === concernSeverity.Mild,
                                                              'tw-text-yellow-400': item?.severity === concernSeverity.Moderate,
                                                              'tw-text-orange-500': item?.severity === concernSeverity.Severe,
                                                      }">
                            {{concernSeverityTranslations?.[user?.get('languagePreference')
                          ||'en']?.[item?.severity]}}
                          </h1>
                          <cs-icon *ngIf="item?.severity === concernSeverity.Severe"
                                   class="tw-w-[13px] tw-h-[13px]"
                                   [iconName]="'severe-vector'"></cs-icon>
                        </div>
                        <div
                          class="tw-font-body tw-text-200 tw-h-[54px] tw-text-gray-700 tw-line-clamp-3 tw-overflow-hidden tw-text-ellipsis">
                          {{problemsCopy[user?.get('languagePreference')
                        ||'en'][item?.class]?.info}}
                        </div>
                      </div>
                    </ng-container>
                  </swiper>
                  <div *ngIf="['HAIR_FRONT'].includes(instantCheckup.type)"
                       class="-tw-mt-15 tw-px-4 tw-overflow-visible tw-relative">
                    <div *ngIf="isTempleBaldnessDetected"
                         class="tw-px-6 tw-shadow-z3 tw-py-4 tw-box-border tw-h-[136px] tw-bg-white tw-rounded-xl tw-z-20 tw-overflow-visible">
                      <div class="tw-font-body tw-font-bold tw-text-500 tw-mb-1">
                        Temple Baldness
                      </div>
                      <div
                        class="tw-font-body tw-text-200 tw-text-gray-700 tw-line-clamp-3 tw-overflow-hidden tw-text-ellipsis">
                        Thinning or loss of hair on forehead sides.
                      </div>
                      <div
                        class="tw-font-body tw-text-200 tw-text-gray-700 tw-h-[54px] tw-line-clamp-3 tw-overflow-hidden tw-text-ellipsis">
                        <b>Causes</b>: genetics, hormones, aging, stress or diet
                      </div>
                    </div>
                    <div *ngIf="!isTempleBaldnessDetected && isShowHairlineDetectionExperiment&& isHairlineDetected"
                         class="tw-px-6 tw-shadow-z3 tw-py-4 tw-box-border tw-h-[136px] tw-bg-white tw-rounded-xl tw-z-20 tw-overflow-visible">
                      <div class="tw-font-body tw-font-bold tw-text-500 tw-mb-1">
                        Hairline Detected
                      </div>
                      <div
                        class="tw-font-body tw-text-200 tw-text-gray-700 tw-line-clamp-3 tw-overflow-hidden tw-text-ellipsis">
                        A perfect male hairline is typically straight or slightly rounded, with no
                        significant recession at the temples.
                      </div>
                      <hr class="tw-my-2">
                      <div class="tw-font-body tw-text-100 tw-text-yellow-400 tw-overflow-hidden"
                           [routerLink]="['/instant-checkup/preview']"
                           [queryParams]="{tag: 'HAIR_FRONT'}">
                        For better detection, comb your hair back <b>(See How)</b>
                      </div>
                    </div>
                  </div>
        </footer>
        <ng-container *ngIf="blurWithoutConcernsDetected && handleBlurPhotosExperiment">
          <div class="tw-h-14">
            <div [ngClass]="(user.get('languagePreference') === 'ta' || user.get('languagePreference') === 'te' || user.get('languagePreference') === 'kn') ? '-tw-translate-y-[117px]': '-tw-translate-y-[85px]'" class="tw-w-[90%] mx-auto tw-left-5 tw-right-5 tw-bg-orange-50 tw-border tw-border-orange-400 tw-px-4 tw-py-4 tw-mb-4 tw-text-left tw-rounded-xl">
              <div class="tw-flex tw-justify-start tw-items-start tw-mb-2">
                <img src="/assets/icons/warning.svg" class="tw-w-4 tw-h-4 tw-mr-1 tw-mt-0.5" />
                <h1 i18n="@@imageNotClear" class="tw-text-orange-400 tw-text-400 tw-font-body tw-font-bold">Image not clear</h1>
              </div>
              <h1 i18n="@@retakePhoto" class="tw-text-200 tw-mb-2">Retake photo, by trying the following things:</h1>
              <div class="tw-text-200 tw-pl-2">
                <h1 i18n="@@cleanCamera">1. Clean your camera</h1>
                <h1 i18n="@@dontMove">2. Don't move while taking picture </h1>
                <h1 i18n="@@useBackCamera">3. Use back camera</h1>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Continue button -->
        <footer class="tw-py-5 tw-px-3 tw-bg-gray-100">
          <div class="tw-flex tw-gap-3">
            <ng-container *ngIf="!handleBlurPhotosExperiment">
              <cs-button (callback)="goCheckAgain()"
                         class="tw-flex-1 tw-bg-transparent tw-text-green-400 tw-border tw-rounded-full"
                         [ngClass]="{
                            'tw-text-200': user?.get('languagePreference') === 'ta',
                            'tw-text-500': user?.get('languagePreference') !== 'ta',
                            'tw-border-green-400': handleBlurPhotosExperiment,
                            'tw-border-black': !handleBlurPhotosExperiment,
                       }">
                <cs-button-text *ngIf='!handleBlurPhotosExperiment && (instantCheckup.type.includes("FACE") || (instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT && instantCheckup?.aiResponse?.hairDetected))'
                                i18n="@@checkAgain">Check Again</cs-button-text>
                <cs-button-text *ngIf='!instantCheckup.type.includes("FACE") && ((instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT || instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_TOP) && !instantCheckup?.aiResponse?.hairDetected)'
                                i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                <cs-button-text *ngIf="instantCheckup.type === 'INVALID'"
                                i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                <cs-button-text *ngIf="instantCheckup.type === 'REPORT'"
                                i18n="@@change">Change</cs-button-text>
              </cs-button>
              <cs-button class="tw-flex-1 tw-bg-green-400 tw-text-white tw-rounded-full"
                         (callback)="continueToNext()">
                <cs-button-text i18n="@@continue">Continue</cs-button-text>
              </cs-button>
            </ng-container>
            <ng-container *ngIf="handleBlurPhotosExperiment">
              <ng-container *ngIf="blurWithoutConcernsDetected">
                <cs-button class="tw-flex-1 tw-border tw-border-green-400 tw-text-green-400 tw-rounded-full"
                           (callback)="continueToNext()">
                  <cs-button-text i18n="@@continue">Continue</cs-button-text>
                </cs-button>
                <cs-button class="tw-flex-1 tw-bg-green-400 tw-text-white tw-rounded-full">
                  <cs-button-text *ngIf='instantCheckup.type.includes("FACE") || (instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT && instantCheckup?.aiResponse?.hairDetected)'
                                  i18n="@@retake">Retake</cs-button-text>
                </cs-button>
              </ng-container>
              <ng-container *ngIf="blurWithConcernsDetected">
                <cs-button class="tw-flex-1 tw-border tw-border-green-400 tw-text-green-400 tw-rounded-full">
                  <cs-button-text *ngIf='instantCheckup.type.includes("FACE") || (instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT && instantCheckup?.aiResponse?.hairDetected)'
                                  i18n="@@retake">Retake</cs-button-text>
                </cs-button>
                <cs-button class="tw-flex-1 tw-bg-green-400 tw-text-white tw-rounded-full"
                           (callback)="continueToNext()">
                  <cs-button-text i18n="@@continue">Continue</cs-button-text>
                </cs-button>
              </ng-container>
              <ng-container *ngIf="!blurWithConcernsDetected && !blurWithoutConcernsDetected">
                <cs-button (callback)="goCheckAgain()"
                           class="tw-flex-1 tw-bg-transparent tw-text-green-400 tw-border-black tw-border tw-rounded-full"
                           [class]="{
                            'tw-text-200': user?.get('languagePreference') === 'ta',
                            'tw-text-500': user?.get('languagePreference') !== 'ta',
                       }">
                  <cs-button-text *ngIf='(instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT && instantCheckup?.aiResponse?.hairDetected) || instantCheckup.type.includes("FACE")'
                                  i18n="@@checkAgain">Check Again</cs-button-text>
                  <cs-button-text *ngIf='((instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT || instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_TOP) && !instantCheckup?.aiResponse?.hairDetected) && !instantCheckup.type.includes("FACE")'
                                  i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                  <cs-button-text *ngIf="instantCheckup.type === 'REPORT'"
                                  i18n="@@change">Change</cs-button-text>
                  <cs-button-text *ngIf="instantCheckup.type === 'INVALID'"
                                  i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                </cs-button>
                <cs-button class="tw-flex-1 tw-bg-green-400 tw-text-white tw-rounded-full"
                           (callback)="continueToNext()">
                  <cs-button-text i18n="@@continue">Continue</cs-button-text>
                </cs-button>
              </ng-container>
            </ng-container>
          </div>
        </footer>
</ng-container>

<pop-up-modal [config]="ui.popUpModal"
              (close)="popUpClosed($event)"></pop-up-modal>

<input class="tw-fixed"
       #cameraInput=""
       id="cameraInput"
       type="file"
       capture="user"
       accept="image/*"
       (change)="uploadImageFromNativeCamera($event)"
       style="top: -100%; left: -100%" />
